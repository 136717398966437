// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../svg";
import { currencyChange } from "../../store/currency";
import { localeChange } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";

// data stubs
import currencies from "../../data/shopCurrencies";
import { url } from "../../services/utils";

function MobileMenu(props) {
    const { mobileMenuState, closeMobileMenu, changeLocale, changeCurrency, categories } = props;
    const [mobileMenu, setMobileMenu] = useState([]);

    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.sideMenuOpen,
    });

    const setMenuItem = (item, count) => {
        let countLayer = 1; //limit loop up to 4 layer
        countLayer++;
        count = count ? count : 1;

        return {
            type: "link",
            label: item.name,
            url: url.category(item),
            children: item.child?.length > 0 && count <= 4 ? item.child.map((childItem) => setMenuItem(childItem, countLayer)) : [],
        };
    };

    useEffect(() => {
        if (categories?.length > 0) {
            let menu = mobileMenu;
            menu.push({
                type: "link",
                label: "All Series",
                url: "/all-series",
                children: [],
            });
            categories.forEach((item) => {
                menu.push(setMenuItem(item));
            });
            menu.push({
                type: "link",
                label: "About Us",
                url: "/about-us",
                children: [],
            },{
                type: "link",
                label: "Investor Relations",
                url: "https://ir2.chartnexus.com/vanzo/index.php",
                children: [],
                external: true,
            });
            setMobileMenu(menu);
        }
    }, [categories]);

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === "language") {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === "currency") {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === "link") {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenu} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
